<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 class=" mt-1 mb-0">
              Jobs
            </h5>
            <div class="d-flex">
              <div class="px-4">
                <h5 class="mt-1 mb-0">
                  <input
                    id="filter-text-box"
                    v-model="searchQuery"
                    type="text"
                    placeholder="Search..."
                  />
                </h5>
              </div>
              <button
                class="btn btn-sm btn-primary"
                @click="initModal()"
              >
                Add new job
              </button>
            </div>
          </div>
          <div
            class="card-body"
            style="height: 40em"
          >
            <ag-grid-vue
              :columnDefs="columnsJobs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationNumberFormatter="paginationNumberFormatter"
              :paginationPageSize="paginationPageSize"
              :rowData="filteredItems"
              class="ag-theme-alpine"
              style="width: 100%; height: 95%;"
              @row-clicked="tableRowClickHandler"
            />
            <JobModal
              id="edit-job-modal"
              ref="editJobModal"
              viewType="edit"
              @tableForceUpdate="tableForceUpdate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
import JobModal from './JobModal.vue';
import { jobStatuses } from './constants';
import { mapActions, mapGetters } from 'vuex';
import { convertCronToUserTime } from '@/components/Jobs/utils';

const cellClass = {
  [`${jobStatuses.CREATED}`]: '',
  [`${jobStatuses.FAILED}`]: 'text-danger',
  [`${jobStatuses.FINISHED}`]: 'text-success',
  [`${jobStatuses.IN_PROCESS}`]: 'fst-italic'
};
export default {
  name: 'Jobs',
  components: {
    AgGridVue,
    JobModal
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      allJobs: 'scheduler/jobs'
    }),
    filteredItems() {
      const queryTrim = this.searchQuery.trim();
      if (queryTrim.length < 1) {
        return this.rowsJobs;
      }
      const query = queryTrim.toLowerCase();
      return this.rowsJobs.filter((item) => {
        return item.Name.toLowerCase().includes(query) ||
          item.Service.toLowerCase().includes(query) ||
          item['Service Group'].toLowerCase().includes(query);
      });
    },
    rowsJobs() {
      return this.allJobs.map((job) => ({
        '#': job.id,
        Name: job.name,
        Service: job.service,
        'Service Group': job.serviceGroup,
        'Working State': job.active ? 'Active' : 'Disabled',
        'Last Status': job.status || '',
        'Schedule UTC': job.expression || '',
        'Schedule Local': convertCronToUserTime(job.expression)  || '',
        'Last Group Fetch': job.lastGroupFetch ? job.lastGroupFetch.split('T')[0] : 'Not Defined'
      }));
    },
    defaultColDef() {
      return {
        filter: true,
        sortable: true
      };
    },
    paginationPageSize() {
      return 30;
    },
    columnsJobs() {
      return [
        { field: '#',
          width: 80
        },
        { field: 'Name',
          width: 150
        },
        { field: 'Service',
          width: 150
        },
        { field: 'Service Group',
          width: 150
        },
        {
          field: 'Working State',
          width: 150,
          cellRenderer: (params) => {
            const colorClass = (params.value) === 'Active'
              ? 'color-green'
              : 'color-red';
            return `<i class="bi bi-circle-fill ${colorClass}"></i> ${params.value}`;
          }
        },
        {
          field: 'Last Status',
          width: 130,
          cellRenderer: (params) => {
            return `<span class="${cellClass[params.value] || ''}">${params.value}</span>`;
          }
        },
        {
          field: 'Schedule UTC',
          width: 150
        },
        {
          field: 'Schedule Local',
          width: 150
        },
        {
          field: 'Last Group Fetch',
          width: 150
        }
      ];
    }
  },
  async mounted() {
    await this.initialise();
  },
  methods: {
    ...mapActions({
      fetchAllJobs: 'scheduler/fetchAllJobs',
    }),

    async initialise() {
      try {
        await this.fetchAllJobs();
      } catch {
        this.$toast.show('Failed to load jobs.', { type: 'error' });
      }
    },
    tableRowClickHandler(rowClickedEvent) {
      const jobId = rowClickedEvent.data['#'];
      const jobStatus = rowClickedEvent.data['Last Status'];
      this.initModal(jobId, jobStatus);
    },
    initModal(jobId){
      this.$refs.editJobModal.initModal(jobId);
    },
    async tableForceUpdate() {
      await this.initialise();
    },
    paginationNumberFormatter(params) {
      return params.value.toLocaleString();
    }
  }
};
</script>

<style>
.color-green {
  color: #198754;
}

.color-red {
  color: #dc3545;
}
</style>
